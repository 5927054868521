<template>
  <div>
    <form @submit.prevent="submitForm">
      <label>
        Email
        <input v-model="form.email" type="email" required>
      </label>
      <div class="radio-button">
        <label>
          <input type="radio" v-model="selectedOption" value="url"> URL
        </label>
        <label>
          <input type="radio" v-model="selectedOption" value="compte"> Compte Wysistat
        </label>
      </div>
      <div v-if="selectedOption === 'url'">
        <div class="urlsform" v-for="(url, index) in form.urls" :key="index">
          <div>
            <label>
              URL {{ index + 1 }}
              <input class="url-input" v-model="form.urls[index]" type="url" required>
            </label>
          </div>
          <div>
            <button type="button" @click="removeUrl(index)" v-if="index !== 0 && index === form.urls.length - 1"
                    class="delete-button">
              <img src="@/assets/delete-icon.webp" alt="Delete" class="img-delete">
            </button>
          </div>
        </div>
        <button type="button" @click="addUrl" v-if="form.urls.length < 10">
          <img src="@/assets/add-icon.webp" alt="Add" class="img-add">
        </button>
      </div>
      <label v-if="selectedOption === 'compte'">
        Compte Wysistat
        <input type="text" v-model="form.compte" required>
      </label>
      <input class="submit" type="submit" value="Envoyer">
    </form>
  </div>
  <div v-if="isLoading" class="modal">
    <div class="modal-content">
      <div class="loader"></div>
      <p>Le résultat est en cours de génération...</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {
        email: "",
        urls: [""],
        compte: ""
      },
      selectedOption: 'url',
      isLoading: false
    };
  },
  watch: {
    selectedOption() {
      if (this.selectedOption === "compte") {
        this.form.urls = [""];
      } else {
        this.form.compte = "";
      }
    }
  },
  methods: {
    submitForm() {
      this.isLoading = true;
      let formData = {
        'email': this.form.email,
        'option': this.selectedOption,
        'urls': this.form.urls,
        'compte': this.form.compte,
      };

      //axios.post('http://localhost:3008/submitForm', formData)
      axios.post('https://api-ecodit.idfr.net/submitForm', formData)
          .then((response) => {
            console.log(response);
            window.location.href = 'https://ecodit.idfr.net/' + response.data;
            this.isLoading = false;
          })
          .catch((error) => {
            console.log(error);
            alert("Une erreur est survenue");
            this.isLoading = false;
          });
    },
    addUrl() {
      if (this.form.urls.length < 10) {
        this.form.urls.push("");
      }
    },
    removeUrl(index) {
      this.form.urls.splice(index, 1);
    }
  },
  name: "FormPage"
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #ff5e85;
  max-width: 500px;
  margin: 0 auto;
}

label {
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
}

.radio-button {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

input[type="text"],
input[type="email"],
input[type="url"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
}

input[type="submit"] {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #7478fa;
  color: white;
  cursor: pointer;
  align-self: center;
}

input[type="submit"]:hover {
  background-color: #454789;
}

button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background: none;
  color: white;
  cursor: pointer;

}

.url {
  margin-top: 20px;
}

.urlsform {
  display: flex;
  flex-wrap: wrap;
}

.url-input{
  margin-bottom: 10px;
}

label {
  margin-right: 10px;
}

input.submit {
  background-color: #7478fa;
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 30px;
  margin-top: 30px;
}

.delete-button:hover {
  background: none;
}

.img-delete {
  width: 20px;
  height: 20px;
}

.img-add {
  width: 25px;
  height: 25px;
}

/* ... (le reste de votre style) */

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #7478fa;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
